export default [
  'React',
  'React Native',
  'Redux',
  'Firebase',
  'MongoDB',
  'Vim',
  'nginx',
  'LetsEncrypt',
  'Scrum',
];
